// Import Fonts:
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');
// Bootstrap variable override:

@import "bootstrap";
@import "variables";
// Project's custom variables or mixin:
@import "functions";
@import "mixins";
@import "custom";
// plugins:
// layouts:
// core
@import "core/project-sitemap";
@import "layout";
@import "sections/header";
@import "sections/footer";
// pages:
@import "pages/home";
@import "pages/discover";
@import "pages/vision";
@import "pages/single-article";
// sections:
@import "sections/widget";
// Override anything at last:
@import "overrides";