
.home-main-sec{
  padding:0px;
  position:relative;
  background-image: url('../img/right-image.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .home-col{   
    position: relative;
    .home-col-middle{
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
      @include media-breakpoint-down(sm){
        height: 40vh !important;
      }      
    }
  }  
  .right-image{
    background-image: url('../img/right-image.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;   
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;    
    z-index: -1;
  }
}

.cybr-btn{
  --primary:hsl(var(--primary-hue),85%,calc(var(--primary-lightness,50) * 1%));
  --shadow-primary:hsl(var(--shadow-primary-hue),90%,50%);
  --primary-hue:0;--primary-lightness:50;--color:hsl(0,0%,100%);
  --font-size:26px;--shadow-primary-hue:180;--label-size:9px;
  --shadow-secondary-hue:60;
  --shadow-secondary:hsl(var(--shadow-secondary-hue),90%,60%);
  --border:4px;--shimmy-distance:5;
  --clip:polygon(0 0,100% 0,100% 100%,95% 100%,95% 90%,85% 90%,85% 100%,8% 100%,0 70%);
  --clip-one:polygon(0 2%,100% 2%,100% 95%,95% 95%,95% 90%,85% 90%,85% 95%,8% 95%,0 70%);
  --clip-two:polygon(0 78%,100% 78%,100% 100%,95% 100%,95% 90%,85% 90%,85% 100%,8% 100%,0 78%);
  --clip-three:polygon(0 44%,100% 44%,100% 54%,95% 54%,95% 54%,85% 54%,85% 54%,8% 54%,0 54%);
  --clip-four:polygon(0 0,100% 0,100% 0,95% 0,95% 0,85% 0,85% 0,8% 0,0 0);
  --clip-five:polygon(0 0,100% 0,100% 0,95% 0,95% 0,85% 0,85% 0,8% 0,0 0);
  --clip-six:polygon(0 40%,100% 40%,100% 85%,95% 85%,95% 85%,85% 85%,85% 85%,8% 85%,0 70%);
  --clip-seven:polygon(0 63%,100% 63%,100% 80%,95% 80%,95% 80%,85% 80%,85% 80%,8% 80%,0 70%);
  
  color:var(--color);
  background:transparent;
  text-transform:uppercase;
  outline:transparent;
  position:relative;
  font-weight:600;
  border:0;
  transition:background .2s;
  font-size: 24px;
  width: 260px;
  &:hover{
    --primary:hsl(var(--primary-hue),85%,calc(var(--primary-lightness,50) * 0.8%));
    background-color: transparent;
  }
  &:active{
    --primary:hsl(var(--primary-hue),85%,calc(var(--primary-lightness,50) * 0.6%));
    background-color: transparent;
  }
  &::before, &::after{
    content:'';
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    -webkit-clip-path:var(--clip);
    clip-path:var(--clip);
    z-index:-1
  }
  &:before{
    background:var(--shadow-primary);
    transform:translate(var(--border),0)
  }
  &:after{
    background:var(--primary)
  }  
}
.cybr-btn__glitch{
  position:absolute;
  top:calc(var(--border) * -1);
  left:calc(var(--border) * -1);
  right:calc(var(--border) * -1);
  bottom:calc(var(--border) * -1);
  background:var(--shadow-primary);
  text-shadow:2px 2px var(--shadow-primary),-2px -2px var(--shadow-secondary);
  -webkit-clip-path:var(--clip);
  clip-path:var(--clip);
  -webkit-animation:glitch 2s infinite;
  animation:glitch 2s infinite;
  display:none;
  &:before{
    content:'';
    position:absolute;
    top:calc(var(--border) * 1);
    right:calc(var(--border) * 1);
    bottom:calc(var(--border) * 1);
    left:calc(var(--border) * 1);
    -webkit-clip-path:var(--clip);
    clip-path:var(--clip);
    background:var(--primary);
    z-index:-1
  }
}
.cybr-btn:hover .cybr-btn__glitch{display:block}
.cybr-btn2{--primary-hue:260}
@-webkit-keyframes glitch {
0%{-webkit-clip-path:var(--clip-one);clip-path:var(--clip-one)}
2%,8%{-webkit-clip-path:var(--clip-two);clip-path:var(--clip-two);transform:translate(calc(var(--shimmy-distance) * -1%),0)}
6%{-webkit-clip-path:var(--clip-two);clip-path:var(--clip-two);transform:translate(calc(var(--shimmy-distance) * 1%),0)}
9%{-webkit-clip-path:var(--clip-two);clip-path:var(--clip-two);transform:translate(0,0)}
10%{-webkit-clip-path:var(--clip-three);clip-path:var(--clip-three);transform:translate(calc(var(--shimmy-distance) * 1%),0)}
13%{-webkit-clip-path:var(--clip-three);clip-path:var(--clip-three);transform:translate(0,0)}
14%,21%{-webkit-clip-path:var(--clip-four);clip-path:var(--clip-four);transform:translate(calc(var(--shimmy-distance) * 1%),0)}
25%{-webkit-clip-path:var(--clip-five);clip-path:var(--clip-five);transform:translate(calc(var(--shimmy-distance) * 1%),0)}
30%{-webkit-clip-path:var(--clip-five);clip-path:var(--clip-five);transform:translate(calc(var(--shimmy-distance) * -1%),0)}
35%,45%{-webkit-clip-path:var(--clip-six);clip-path:var(--clip-six);transform:translate(calc(var(--shimmy-distance) * -1%))}
40%{-webkit-clip-path:var(--clip-six);clip-path:var(--clip-six);transform:translate(calc(var(--shimmy-distance) * 1%))}
50%{-webkit-clip-path:var(--clip-six);clip-path:var(--clip-six);transform:translate(0,0)}
55%{-webkit-clip-path:var(--clip-seven);clip-path:var(--clip-seven);transform:translate(calc(var(--shimmy-distance) * 1%),0)}
60%{-webkit-clip-path:var(--clip-seven);clip-path:var(--clip-seven);transform:translate(0,0)}
31%,61%,100%{-webkit-clip-path:var(--clip-four);clip-path:var(--clip-four)}
}
@keyframes glitch {
0%{-webkit-clip-path:var(--clip-one);clip-path:var(--clip-one)}
2%,8%{-webkit-clip-path:var(--clip-two);clip-path:var(--clip-two);transform:translate(calc(var(--shimmy-distance) * -1%),0)}
6%{-webkit-clip-path:var(--clip-two);clip-path:var(--clip-two);transform:translate(calc(var(--shimmy-distance) * 1%),0)}
9%{-webkit-clip-path:var(--clip-two);clip-path:var(--clip-two);transform:translate(0,0)}
10%{-webkit-clip-path:var(--clip-three);clip-path:var(--clip-three);transform:translate(calc(var(--shimmy-distance) * 1%),0)}
13%{-webkit-clip-path:var(--clip-three);clip-path:var(--clip-three);transform:translate(0,0)}
14%,21%{-webkit-clip-path:var(--clip-four);clip-path:var(--clip-four);transform:translate(calc(var(--shimmy-distance) * 1%),0)}
25%{-webkit-clip-path:var(--clip-five);clip-path:var(--clip-five);transform:translate(calc(var(--shimmy-distance) * 1%),0)}
30%{-webkit-clip-path:var(--clip-five);clip-path:var(--clip-five);transform:translate(calc(var(--shimmy-distance) * -1%),0)}
35%,45%{-webkit-clip-path:var(--clip-six);clip-path:var(--clip-six);transform:translate(calc(var(--shimmy-distance) * -1%))}
40%{-webkit-clip-path:var(--clip-six);clip-path:var(--clip-six);transform:translate(calc(var(--shimmy-distance) * 1%))}
50%{-webkit-clip-path:var(--clip-six);clip-path:var(--clip-six);transform:translate(0,0)}
55%{-webkit-clip-path:var(--clip-seven);clip-path:var(--clip-seven);transform:translate(calc(var(--shimmy-distance) * 1%),0)}
60%{-webkit-clip-path:var(--clip-seven);clip-path:var(--clip-seven);transform:translate(0,0)}
31%,61%,100%{-webkit-clip-path:var(--clip-four);clip-path:var(--clip-four)}
}