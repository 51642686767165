
.vision-page{
  background-image: url('../img/vision-bg.png');
  background-size: cover;
  background-position: center;
  h1{
    text-align: center;
  }
  .vision-glass{
    background-color:$white;
    padding: 36px;
    border-radius: 18px;
    @include media-breakpoint-down(md){
      padding: 18px;
    }
  }
}